import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`1991年、香川県に生まれたWebエンジニア。またの名をうずらさん。`}</p>
    <p>{`早稲田大学在学中にWeb制作を、卒業後金融系SIerでエンジニアとして上流工程から下流工程まで一連の開発を経験。2019年にフリーランスへ転身。フロントエンド技術を中心にWebアプリケーション・システムの開発を行っている。`}</p>
    <p>{`サンフランシスコ・上海への海外留学経験から、むしろ日本の良さを再認識。Webエンジニアという仕事を通して日本をより良くするシステム開発に携わっていきたい。`}</p>
    <p>{`趣味はJリーグと個人開発。FC東京の大ファン。一生青と赤。ライブラリはReactが一番好き。`}</p>
    <p>{`プライベートでは1児の父。可愛くてしょうがない！尋常じゃない成長力と未来を備えた我が子に負けないように、昨日よりも1レベルアップできるように精進している。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      